import { gql } from "@apollo/client";

const runByIdRunFragment = gql`
    fragment RunByIdRun on Run {
        id
        status
    }
`;

export const RUN_BY_ID_QUERY = gql`
    query RunById($id: ID!, $threadId: ID!, $assistantId: String!) {
        run(id: $id, threadId: $threadId, assistantId: $assistantId) {
            ...RunByIdRun
        }
    }
    ${runByIdRunFragment}
`;
