import { FunctionComponent, ReactNode } from "react";
import styles from "@/components/ai-chat-assistant/message/message.module.scss";
import { IconExternal, LazyLoad, Skeleton } from "@webomat/webomat-ui-react/build/esm";
import { GQLMessageRole } from "@/utils/graphql.generated";
import ReactMarkdown from "react-markdown";
import { GQLPublicAssistantByIdAvatarFragment } from "@/queries/assistant.generated";

interface MessageProps {
    message?: string | ReactNode;
    isTyping?: boolean;
    avatar?: GQLPublicAssistantByIdAvatarFragment | null;
    showAvatar?: boolean;
    showFooter?: boolean;
    footer?: string;
    role: GQLMessageRole;
    onClick?: () => void;
    key?: React.Key;
}

export const Message: FunctionComponent<MessageProps> = ({
    role,
    message,
    avatar,
    showAvatar = true,
    showFooter = true,
    isTyping,
    footer,
    onClick,
}) => {
    const CustomTag = onClick ? "button" : "div";

    return (
        <div
            className={`${styles.Wrapper} ${styles[`Wrapper--${role}`]} ${isTyping ? styles["Wrapper--is-typing"] : ""} ${
                showAvatar && avatar?.sizes?.[0] ? styles["Wrapper--with-avatar"] : ""
            } ${onClick ? styles["Wrapper--button"] : ""}`.trim()}
        >
            <div className={styles.Body}>
                {showAvatar && avatar?.sizes?.[0] && (
                    <div className={styles.Author}>
                        <div className={styles.AuthorImage}>
                            <LazyLoad
                                component="image"
                                imageProps={{
                                    breakpoints: {
                                        default: avatar.sizes[0].src,
                                    },
                                }}
                                width={avatar.sizes[0].width}
                                height={avatar.sizes[0].height}
                            />
                        </div>
                    </div>
                )}
                <CustomTag className={styles.Message} onClick={onClick}>
                    {isTyping ? (
                        <div className={styles.Typing}>
                            <Skeleton width="8px" height="8px" rounded="full" className={styles.TypingSkeleton} />
                            <Skeleton width="8px" height="8px" rounded="full" className={styles.TypingSkeleton} />
                            <Skeleton width="8px" height="8px" rounded="full" className={styles.TypingSkeleton} />
                        </div>
                    ) : typeof message === "string" ? (
                        <ReactMarkdown
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    return !inline ? (
                                        <div className={styles.CodeWrapper}>
                                            <div className={styles.CodeInner}>
                                                <code className={className} {...props}>
                                                    {children}
                                                </code>
                                            </div>
                                        </div>
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    );
                                },
                                a({ href, children }) {
                                    return (
                                        <a href={href || "#"} target="_blank">
                                            <IconExternal />
                                            {children}
                                        </a>
                                    );
                                },
                            }}
                        >
                            {message}
                        </ReactMarkdown>
                    ) : (
                        message
                    )}
                </CustomTag>
            </div>
            {showFooter && footer && <div className={styles.Footer}>{footer}</div>}
        </div>
    );
};
