import React from "react";
import { GraphQLProvider, ThemeProvider, ToastProvider } from "@webomat/webomat-ui-react/build/esm";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "./tailwind.config";
import { AiChatAssistant } from "./components/ai-chat-assistant/ai-chat-assistant";
import { cache } from "@/utils/graphql";
import { IntlProvider } from "./providers/intl-provider";

function App() {
    const fullConfig = resolveConfig(tailwindConfig);
    const theme = fullConfig.theme;

    return (
        <ThemeProvider theme={theme}>
            <ToastProvider>
                <IntlProvider locale="de">
                    <GraphQLProvider cache={cache} lang="de" uri={`${process.env.REACT_APP_API_URL}/graphql`}>
                        <AiChatAssistant id="b333cfb3-6a80-4874-b0b6-82f1e68e10cd" renderMethod="button" />
                    </GraphQLProvider>
                </IntlProvider>
            </ToastProvider>
        </ThemeProvider>
    );
}

export default App;
