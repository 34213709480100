import { KeyValueObject } from "@webomat/webomat-ui-react/build/esm/types/general";

export function generateCSSFromKeyframes(keyframes: KeyValueObject, addPrefixes: boolean = false): string {
    const prefixProperties = (property: string, value: string): string[] => {
        if (!addPrefixes) return [`${property}: ${value};`];
        const prefixedProperties: Record<string, string[]> = {
            transform: ["-webkit-transform", "-ms-transform"],
            "background-position": ["-webkit-background-position"],
        };
        const prefixes = prefixedProperties[property] || [];
        return [...prefixes.map((prefixed) => `${prefixed}: ${value};`), `${property}: ${value};`];
    };
    let css = "";
    for (const [name, frames] of Object.entries(keyframes)) {
        css += `@keyframes ${name} {\n`;
        for (const [key, styles] of Object.entries(frames)) {
            css += `  ${key} {\n`;
            for (const [property, value] of Object.entries(styles as any)) {
                const prefixedRules = prefixProperties(
                    property.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`),
                    value as any,
                );
                css += `    ${prefixedRules.join("\n    ")}\n`;
            }
            css += `  }\n`;
        }
        css += `}\n\n`;
    }
    return css;
}

export function convertRemToPx(obj: Record<string, any>, basePx: number = 16): Record<string, any> {
    const updatedObj: Record<string, any> = {};
    for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
            updatedObj[key] = convertRemToPx(obj[key], basePx);
        } else if (typeof obj[key] === "string" && obj[key].includes("rem")) {
            const value = parseFloat(obj[key].replace("rem", "").trim());
            updatedObj[key] = `${value * basePx}px`;
        } else {
            updatedObj[key] = obj[key];
        }
    }
    return updatedObj;
}
