import { IntlProvider as ReactIntlProvider, MessageFormatElement } from "react-intl";
import { FunctionComponent, PropsWithChildren, useEffect, useState } from "react";

export interface IntlProviderProps {
    locale: string;
}

export const IntlProvider: FunctionComponent<PropsWithChildren<IntlProviderProps>> = ({ locale, children }) => {
    const [messages, setMessages] = useState<Record<string, MessageFormatElement[]> | Record<string, string> | null>(null);

    useEffect(() => {
        const loadMessages = async () => {
            const messages = await import(`../locales/${locale}.json`);
            setMessages(messages.default);
        };

        loadMessages();
    }, [locale]);

    if (!messages) return null;

    return (
        <ReactIntlProvider messages={messages} locale={locale}>
            {children}
        </ReactIntlProvider>
    );
};
