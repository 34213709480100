import { gql, useMutation } from "@apollo/client";
import { GQLCreateMessageMutation, GQLCreateMessageMutationVariables } from "./message.generated";

export const CREATE_MESSAGE_MUTATION = gql`
    mutation CreateMessage($threadId: ID!, $assistantId: String!, $content: String!) {
        createMessage(threadId: $threadId, assistantId: $assistantId, content: $content) {
            status
            message {
                id
                role
                createdAt
                content {
                    type
                    text {
                        value
                    }
                }
                run {
                    id
                }
            }
        }
    }
`;

export const useCreateMessageMutation = () => {
    const [createMessage, { loading, error, data }] = useMutation<GQLCreateMessageMutation, GQLCreateMessageMutationVariables>(
        CREATE_MESSAGE_MUTATION,
        {
            onCompleted: () => {
                //
            },
        },
    );

    return {
        createMessage: createMessage,
        loading: loading,
        error: error,
        data: data,
    };
};
