module.exports = {
    content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
    safelist: [
        {
            pattern: /order-(1|2|3|4|5|6|7|8|9|10|11|12|first|last)/,
        },
        {
            pattern: /^opacity-/,
        },
        {
            pattern: /^animate-/,
        },
    ],
    theme: {
        fontFamily: {
            body: ["Open Sans", "sans-serif"],
        },
        fontWeight: {
            light: "300",
            normal: "400",
            medium: "500",
            semibold: "600",
            bold: "700",
        },
        screens: {
            sm: "576px",
            md: "768px",
            lg: "992px",
            xl: "1200px",
            "2xl": "1500px",
        },
        fontSize: {
            "3xs": "12px",
            "2xs": "13px",
            xs: "14px",
            sm: "16px",
            md: "18px",
            lg: "20px",
            xl: "28px",
            "2xl": "36px",
            "3xl": "46px",
            "4xl": "62px",
            "5xl": "82px",
            "6xl": "100px",
        },
        colors: {
            transparent: "transparent",
            white: "#ffffff",
            black: "#000000",
            primary: {
                100: "var(--color-primary-100)",
                200: "var(--color-primary-200)",
                300: "var(--color-primary-300)",
                400: "var(--color-primary-400)",
                500: "var(--color-primary-500)",
                600: "var(--color-primary-600)",
                700: "var(--color-primary-700)",
                800: "var(--color-primary-800)",
                900: "var(--color-primary-900)",
            },
            gray: {
                100: "#f8fafd",
                200: "#f0f2f5",
                300: "#e4e6ea",
                400: "#ced1d5",
                500: "#b6b8be",
                600: "#92949b",
                700: "#64666c",
                800: "#38393e",
                900: "#1c1e24", // rgb: 28, 30, 36
            },
            orange: {
                100: "#fef8f0",
                200: "#fdedda",
                300: "#fbe2c3",
                400: "#f8cd96",
                500: "#f5b769", // rgb: 245, 183, 105
                600: "#dda55f",
                700: "#b8894f",
                800: "#936e3f",
                900: "#785a33",
            },
            red: {
                100: "#feeeee",
                200: "#fcd3d3",
                300: "#fbb9b9",
                400: "#f78585",
                500: "#f45050", // rgb: 244, 80, 80
                600: "#dc4848",
                700: "#b73c3c",
                800: "#923030",
                900: "#782727",
            },
            green: {
                100: "#e9f9ed",
                200: "#c9f0d2",
                300: "#a8e7b7",
                400: "#67d581",
                500: "#26c34b", // rgb: 38, 195, 75
                600: "#22b044",
                700: "#1d9238",
                800: "#17752d",
                900: "#136025",
            },
            yellow: {
                100: "#fdfced",
                200: "#fbf8d1",
                300: "#f9f3b5",
                400: "#f4ea7e",
                500: "#efe146", // rgb: 239, 225, 70
                600: "#d7cb3f",
                700: "#b3a935",
                800: "#8f872a",
                900: "#756e22",
            },
            blue: {
                100: "#C5E1F8",
                200: "#A1CDF3",
                300: "#7DBAEF",
                400: "#58A7EA",
                500: "#3494E6", // rgb: 52, 148, 230
                600: "#1978C9",
                700: "#135A97",
                800: "#0C3C65",
                900: "#061F33",
            },
            purple: {
                100: "#D2BDF7",
                200: "#B999F2",
                300: "#A074ED",
                400: "#8850E9",
                500: "#6F2CE4", // rgb: 111, 44, 228
                600: "#5518BF",
                700: "#3F128E",
                800: "#290C5C",
                900: "#13052A",
            },
            pink: {
                100: "#EFADDE",
                200: "#E88AD0",
                300: "#E168C3",
                400: "#DA46B5",
                500: "#CF29A5", // rgb: 207, 41, 165
                600: "#A02080",
                700: "#71165A",
                800: "#430D35",
                900: "#140410",
            },
        },
        boxShadow: {
            DEFAULT:
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05)",
            button: "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(28, 30, 36, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(28, 30, 36, 0.08) 0px 2px 5px 0px",
            "button-primary": "var(--shadows-button-primary)",
            "button-orange":
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(245, 183, 105, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(245, 183, 105, 0.08) 0px 2px 5px 0px",
            "button-red":
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(244, 80, 80, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(244, 80, 80, 0.08) 0px 2px 5px 0px",
            "button-green":
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(38, 195, 75, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(38, 195, 75, 0.08) 0px 2px 5px 0px",
            "button-yellow":
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(239, 225, 70, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(239, 225, 70, 0.08) 0px 2px 5px 0px",
            "button-blue":
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(52, 148, 230, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(52, 148, 230, 0.08) 0px 2px 5px 0px",
            "button-purple":
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(111, 44, 228, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(111, 44, 228, 0.08) 0px 2px 5px 0px",
            "button-pink":
                "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(207, 41, 165, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(207, 41, 165, 0.08) 0px 2px 5px 0px",
            "button-focus": "rgba(0, 0, 0, 0.08) 0px 1px 1px 0px, rgba(28, 30, 36, 0.11) 0px 0px 0px 1px, rgba(28, 30, 36, 0.1) 0px 0px 0px 4px",
            "button-primary-focus": "var(--shadows-button-primary-focus)",
            "button-orange-focus":
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05), rgba(245, 183, 105, 0.3) 0px 0px 0px 4px",
            "button-red-focus":
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05), rgba(244, 80, 80, 0.3) 0px 0px 0px 4px",
            "button-green-focus":
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05), rgba(38, 195, 75, 0.3) 0px 0px 0px 4px",
            "button-yellow-focus":
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05), rgba(239, 225, 70, 0.3) 0px 0px 0px 4px",
            "button-blue-focus":
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05), rgba(52, 148, 230, 0.3) 0px 0px 0px 4px",
            "button-purple-focus":
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05), rgba(111, 44, 228, 0.3) 0px 0px 0px 4px",
            "button-pink-focus":
                "0 0 0 1px rgba(28, 30, 36, .05), 0 2px 5px 0 rgba(28, 30, 36, .05), 0 1px 2.5px 0 rgba(28, 30, 36, .05), 0 1px 2px 0 rgba(28, 30, 36, .05), rgba(207, 41, 165, 0.3) 0px 0px 0px 4px",
            box: "0 40px 90px rgba(28, 30, 36, .11), 0 15px 35px rgba(28, 30, 36, .15), 0 1px 15px rgba(28, 30, 36, .1)",
            "box-light": "0 35px 80px rgba(28, 30, 36, .07), 0 13px 30px rgba(28, 30, 36, .09), 0 1px 13px rgba(28, 30, 36, .07)",
            "box-extra-light": "0 15px 70px rgba(28, 30, 36, .04), 0 2px 9px rgba(28, 30, 36, .04)",
            card: "0 0 0 1px rgba(28, 30, 36, .01), 0 7px 14px 0 rgba(28, 30, 36, .1), 0 3px 6px 0 rgba(0, 0, 0, .02)",
            "keyboard-navigation": "0 0 0 2px #427DDD, inset 0 0 0 2px hsla(0, 0%, 100%, 0.9)",
            none: "none",
        },
        transitionProperty: {
            none: "none",
            all: "all",
            shadow: "box-shadow",
            transform: "transform",
            opacity: "opacity",
            move: "transform, opacity, width, height, top, left, visibility",
            colors: "background-color, border-color, color, fill, stroke",
            width: "width",
        },
        borderRadius: {
            DEFAULT: "4px",
            lg: "8px",
            xl: "16px",
            full: "9999px",
            none: "0",
        },
        transitionDuration: {
            200: "200ms",
            300: "300ms",
            600: "600ms",
            900: "900ms",
        },
        transitionTimingFunction: {
            "out-cubic": "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
            "in-out-cubic": "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
            "in-out-expo": "cubic-bezier(1.000, 0.000, 0.000, 1.000)",
            "in-out-quart": "cubic-bezier(0.770, 0.000, 0.180, 1.000);",
            "out-back": "cubic-bezier(.26,2.05,.59,1.14)",
            "out-expo": "cubic-bezier(0.190, 1.000, 0.220, 1.000)",
            "in-out-custom": "cubic-bezier(0.620, 0.020, 0.070, 1.000)",
        },
        animation: {
            none: "none",
            spin: "spin 600ms linear infinite",
            "spin-slow": "spin 50000ms linear infinite",
            loading: "loading 2s linear infinite",
            shimmer: "shimmer 1.7s linear infinite",
            "pulse-ring": "pulse-ring 1.25s cubic-bezier(0.215, 0.610, 0.355, 1.000) infinite",
            "pulse-dot": "pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite",
            "enter-top": "enter-top 0.2s ease-out forwards",
            "leave-top": "leave-top 0.2s ease-in forwards",
            "enter-bottom": "enter-bottom 0.2s ease-out forwards",
            "leave-bottom": "leave-bottom 0.2s ease-in forwards",
        },
        keyframes: {
            spin: {
                from: {
                    transform: "rotate(0deg)",
                },
                to: {
                    transform: "rotate(360deg)",
                },
            },
            loading: {
                from: {
                    "background-position": "0 0",
                },
                to: {
                    "background-position": "50px 50px",
                },
            },
            "pulse-ring": {
                "0%": {
                    transform: "scale(.33)",
                },
                "80%": {
                    opacity: "0",
                },
                "100%": {
                    opacity: "0",
                },
            },
            "pulse-dot": {
                "0%": {
                    transform: "scale(.8)",
                },
                "50%": {
                    transform: "scale(1)",
                },
                "100%": {
                    transform: "scale(.8)",
                },
            },
            shimmer: {
                "100%": {
                    transform: "translateX(100%)",
                },
            },
            "enter-top": {
                "0%": { transform: "translateY(-10px)", opacity: "0" },
                "100%": { transform: "translateY(0)", opacity: "1" },
            },
            "leave-top": {
                "0%": { transform: "translateY(0)", opacity: "1" },
                "100%": { transform: "translateY(-10px)", opacity: "0" },
            },
            "enter-bottom": {
                "0%": { transform: "translateY(10px)", opacity: "0" },
                "100%": { transform: "translateY(0)", opacity: "1" },
            },
            "leave-bottom": {
                "0%": { transform: "translateY(0)", opacity: "1" },
                "100%": { transform: "translateY(10px)", opacity: "0" },
            },
        },
        extend: {
            maxWidth: {
                "screen-3xl": "1920px",
                "screen-4xl": "2164px",
            },
            rotate: {
                270: "270deg",
            },
            transitionDelay: {
                250: "250ms",
                350: "350ms",
                400: "400ms",
                450: "450ms",
                600: "600ms",
                800: "800ms",
                900: "900ms",
                1100: "1100ms",
                1200: "1200ms",
                1300: "1300ms",
                1400: "1400ms",
            },
        },
    },
};
