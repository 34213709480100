import { gql } from "@apollo/client";

const threadByIdMessageFragment = gql`
    fragment ThreadByIdMessage on Message {
        id
        role
        createdAt
        content {
            type
            text {
                value
            }
        }
    }
`;

const threadByIdThreadFragment = gql`
    fragment ThreadByIdThread on Thread {
        id
        messages {
            ...ThreadByIdMessage
        }
    }
    ${threadByIdMessageFragment}
`;

export const THREAD_BY_ID_QUERY = gql`
    query ThreadById($id: ID, $assistantId: String!) {
        thread(id: $id, assistantId: $assistantId) {
            ...ThreadByIdThread
        }
    }
    ${threadByIdThreadFragment}
`;
